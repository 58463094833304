import React from 'react';
import { useInView } from 'react-intersection-observer';
import ym from 'react-yandex-metrika';
import { useLocation } from "@reach/router";
import * as PropTypes from "prop-types";
import Modal from "react-modal";

import '../../../styles/form/forms/callBackForm.scss'

import useLanguage from "../../../hooks/useLanguage";

import iconClose from "../../../images/icons/buttons/close.svg";
import iconCloseMobile from "../../../images/icons/buttons/close-mobile.svg";

import ResultScreen from '../../components/ResultScreen.js';
import ProcessScreen from '../../components/ProcessScreen.js';
import CompanyLeadForm from '../../components/CompanyLeadForm/CompanyLeadForm.js';
import Button from "../../../components/Button";

import { sendCompanyLeadDataForm } from '../../models/form/methods/sendCompanyLeadDataForm.js';

import {
  FORM_SCREENS,
  FORM_SCREEN_DEFAULT, 
  MESSAGES, 
  SCREEN_DATA,
} from './constants.js';

const SignUpCompanyLeadForm = ({ textSubmit, canBeFixed = "false" }) => {
  Modal.setAppElement("#___gatsby");

  const [language] = useLanguage();
  const locales = MESSAGES[language];

  const { href, state } = useLocation();

  const signUpFosFormOpened = state?.signUpFosFormOpened || false;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [modalIsOpen, setIsOpen] = React.useState(signUpFosFormOpened);

  const [screenData, setScreenData] = React.useState(SCREEN_DATA);

  const submitCompanyLeadData = React.useCallback((formValues) => {
    setScreenData({screen: 'process'});

    const sentData = new FormData();

    Object.keys(formValues).forEach(key => {
      const fieldValue = formValues[key];
      if (fieldValue) {
        if (key === 'files') {
          if(fieldValue.length) {
            fieldValue.forEach(file => sentData.append("files[]", file, file.name));
            return;
          }
          return;
        }
        sentData.append(key, fieldValue);
      }
    });

    sentData.append("source", href); 

    sendCompanyLeadDataForm(sentData)
      .then((responseCompanyLeadData) => {
        if (responseCompanyLeadData.status === 'success') {
          setScreenData({screen: 'success'});
          ym('reachGoal', 'fos_v3_send', { params: { url: href } });
        } else {
          setScreenData({screen: 'error'});
        }
      })
      .catch(() => {
        setScreenData({screen: 'error'});
      });
  }, [setScreenData]);

  const handleResultButtonClick = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = React.useCallback(() => {
    setScreenData({ screen: FORM_SCREEN_DEFAULT });

    setIsOpen(true);
  }, [setScreenData, setIsOpen]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);

  }, [setScreenData, setIsOpen])

  const resultsMessagesScreen = React.useMemo(() => {
    let title = '';
    let description = '';

    if (screenData.screen === FORM_SCREENS.error) {
      title = screenData.title || locales.formCompanyLead.result.error;
      description = screenData.description || locales.formCompanyLead.result.errorDescription;
    }

    if (screenData.screen === FORM_SCREENS.success) {
      title = screenData.title || locales.formCompanyLead.result.success;
      description = screenData.description;
    }

    return {
      title,
      description
    }
  }, [locales, screenData]);

  return (
    <div ref={ref}>
      <div className={`button-fos-wrapper ${canBeFixed === "true" ? "button-fos-wrapper-canfixed" : ""} ${inView === false ? "fixedBtn": ""}`}>
        <Button click={onOpen}>
            {textSubmit ? textSubmit : 'Подключиться'}
        </Button>
      </div>

      <Modal
        className="callBackForm"
        closeTimeoutMS={100}
        isOpen={modalIsOpen}
        onRequestClose={onClose}
        overlayClassName="callBackForm-Overlay"
        portalClassName="FOS-callBackForm"
      >
        {screenData.screen === FORM_SCREENS.form && (
          <CompanyLeadForm 
            onSubmit={submitCompanyLeadData}
          />
        )}
        {(screenData.screen === FORM_SCREENS.success || screenData.screen === FORM_SCREENS.error) &&
          <ResultScreen
            buttonTitle={locales.formUser.result.button}
            description={resultsMessagesScreen.description} 
            title={resultsMessagesScreen.title} 
            status={screenData.screen}
            handleButtonClick={handleResultButtonClick} 
          />
        }

        {screenData.screen === FORM_SCREENS.process && <ProcessScreen text={locales.formProcess} />}

        <a onClick={onClose}>
          <img src={iconClose} className="callBackForm-iconClose" />
          <img src={iconCloseMobile} className="callBackForm-iconCloseMobile" />
        </a>
      </Modal>
    </div>
  );
};

SignUpCompanyLeadForm.propTypes = {
  fosDataStart: PropTypes.object,
  fosDataUser: PropTypes.object,
  textSubmit: PropTypes.string,
};

export default SignUpCompanyLeadForm;
